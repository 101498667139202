import React from 'react';
import {graphql} from 'gatsby';
import loadable from '@loadable/component';

const PageHeader = loadable(() => import('../components/PageHeader'));
const Content = loadable(() => import('../components/Content'));
const Layout = loadable(() => import('../components/Layout'));
const ActualitesSection = loadable(() => import('../components/actualite/ActualitesSection'));


// Export Template for use in CMS preview
export const DefaultPageTemplate = ({
  title,
  subtitle,
    slug,
  featuredImage,
    posts,
  body
}) => (
  <main className="DefaultPage">
    <PageHeader
      title={title}
      subtitle={subtitle}
      backgroundImage={featuredImage}
    />

    <section className="section">
      <div className="container">
        <Content source={body} />
      </div>
    </section>
  </main>
);

const DefaultPage = ({ data: { page, posts } }) => (
  <Layout
    meta={page.frontmatter.meta || false}
    title={page.frontmatter.title || false}
  >
    <DefaultPageTemplate {...page.frontmatter}
                         body={page.html}
                         posts={posts.edges.map(post => ({
        ...post.node,
        ...post.node.frontmatter,
        ...post.node.fields
    }))} />
  </Layout>
)
export default DefaultPage

export const pageQuery = graphql`
  query DefaultPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        subtitle
        slug
        featuredImage
      }
    }
    
    posts: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "actualites" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            date
            categories {
              category
            }
            featuredImage
          }
        }
      }
    }
  }
`
